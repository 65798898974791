
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { newPlot, react, purge } from "plotly.js-dist";
import { Chart } from "@/interfaces";

@Component
export default class PlotlyChart extends Vue {
    @Prop({ type: String, required: true }) id!: string;
    @Prop({ type: Object, default: { data: [] } }) chartOptions!: Chart;

    @Watch("chartOptions", { immediate: true })
    onChartOptionsChanged() {
        /**
         * Check if DOM is ready, else initialize chart after next tick
         */
        if (this.$el) {
            this.initChart();
            return;
        }

        this.$nextTick(() => {
            this.initChart();
        });
    }

    plot!: object | null;

    initChart() {
        this.updateLayout();
        if (this.plot) {
            newPlot(this.id, this.chartOptions.data, this.chartOptions.layout, {
                responsive: true
            }).then(response => {
                this.plot = response;
            });
        } else {
            purge(this.id);
            react(this.id, this.chartOptions.data, this.chartOptions.layout, {
                responsive: true
            });
        }
    }

    updateLayout() {
        if (this.chartOptions.layout) {
            this.chartOptions.layout.legend = {
                orientation: "h"
            };

            this.chartOptions.layout.margin = { t: 28, b: 28, l: 28, r: 28 };
            this.chartOptions.layout.yaxis = { autorange: true };
            this.chartOptions.layout.xaxis = { autorange: true };
        }
    }
}
